import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
const Landingpage = React.lazy(() => import("pages/Landingpage"));
const VoterDetailpage = React.lazy(() => import("pages/VoterDetailpage"));
const UrduMainPage = React.lazy(() => import("pages/Landingpage/index-ur"));
const SearchComponent = React.lazy(() => import("pages/search"));

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          <Route path="/" element={<Landingpage />} />
          <Route path="*" element={<Landingpage />} />
          <Route path="/ur" element={<UrduMainPage />} />
          <Route path="/vote" element={<VoterDetailpage />} />
          <Route path="/yourvote" element={<VoterDetailpage />} />
          <Route path="/search" element={<SearchComponent />} />
          {/* <Route path="/landingpage" element={<Landingpage />} /> */}
          {/* <Route exact path="/v2" render={() => {window.location.href="normal_redirect.html"}} /> */}
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
